import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import useCards from "../utils/useCards";
import { StaticImage } from "gatsby-plugin-image";

import {
  IconAC,
  IconTV,
  IconBath,
  IconBed,
  IconFridge,
  IconFun,
  IconPeople,
  IconWifi,
} from "../atoms/Icons";
import ImageContainer from "../components/ImageContainer";

const CardPage = ({ pageContext }) => {
  const data = useCards();

  const { slug } = pageContext;
  const slugString = JSON.stringify(slug);

  const dataFiltered = data.filter(
    (i) => JSON.stringify(i.slug) === slugString
  );

  console.log(dataFiltered);

  return (
    <Layout>
      {dataFiltered.map((i) => (
        <>
          <Seo title={i.title} />
          <div className="px-2 lg:px-0">
            {/* carousel */}
            <ImageContainer image={i.imageContainer} id={i.id} />

            <div className="lg:grid lg:grid-cols-2 gap-8 place-content-center ">
              {/* container data left */}
              <div className="mx-auto lg:mx-0 lg:max-w-xl">
                <p className="paragraph font-bold pt-8">{i.title}</p>
                <div className="flex flex-col gap-8 w-auto">
                  <p className="paragraph max-w-lg py-4">{i.longDesc}</p>
                  <div className="grid grid-cols-2 gap-4 lg:max-w-1/2 -mt-2">
                    <div className="flex items-center">
                      <IconBed fill="black" />
                      <p className="paragraph ml-2 mt-0.5">
                        {i.beds} {i.beds > 1 ? "camas" : "cama"}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <IconPeople fill="black" />
                      <p className="paragraph ml-2 mt-0.5">
                        {i.persons} personas
                      </p>
                    </div>
                    {i.tags.includes("baño") && (
                      <div className="flex items-center">
                        <IconBath fill="black" />
                        <p className="paragraph ml-2 mt-0.5">Baño privado</p>
                      </div>
                    )}
                    {i.tags.includes("cocina") && (
                      <div className="flex items-center">
                        <IconFridge fill="black" />
                        <p className="paragraph ml-2 mt-0.5">
                          Cocina <br />
                          equipada
                        </p>
                      </div>
                    )}
                    {i.tags.includes("wifi") && (
                      <div className="flex items-center">
                        <IconWifi fill="black" />
                        <p className="paragraph ml-2 mt-0.5">
                          Conexión a internet
                        </p>
                      </div>
                    )}
                    {i.tags.includes("wifi-complejo") && (
                      <div className="flex items-center">
                        <IconWifi fill="black" />
                        <p className="paragraph ml-2 mt-0.5">
                          Wi-Fi en <br /> el complejo
                        </p>
                      </div>
                    )}
                    {i.tags.includes("ventilador") && (
                      <div className="flex items-center">
                        <IconFun fill="black" />
                        <p className="paragraph ml-2">Ventilador</p>
                      </div>
                    )}
                    {i.tags.includes("clima") && (
                      <div className="flex items-center">
                        <IconAC />
                        <p className="paragraph ml-2">Clima</p>
                      </div>
                    )}
                    {i.tags.includes("tv") && (
                      <div className="flex items-center">
                        <IconTV />
                        <p className="paragraph ml-2">TV</p>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center justify-between lg:justify-start gap-8  md:mx-0  py-4">
                    <a href="https://wa.me/527531103093">
                      <button className="button-primary">
                        Hacé tu reserva
                      </button>
                    </a>
                    <Link to="/card" className="mx-auto lg:mx-0">
                      <button className="button-secondary">
                        Ver más Cabañas
                      </button>
                    </Link>
                  </div>

                  <div>
                    <p className="paragraph font-bold pb-4">
                      A tener en cuenta
                    </p>
                    <p>
                      Check in: 10 - 12 hs. <br />
                      Check out: 13 hs.
                    </p>
                  </div>
                </div>
              </div>

              {/* container data right */}
              <div className="hidden lg:block">
                <p className="paragraph font-bold pt-8 ">Donde estamos</p>
                <div className="flex flex-col gap-8 py-4 ">
                  <p className="paragraph">
                    Para llegar a Villas Cheyos, por tierra, toma de referencia
                    la ciudad de Lázaro Cárdenas. Allí puedes tomarte un autobús
                    hacia Barra de Nexpa.
                  </p>

                  <p className="paragraph">
                    Si vienes en avión, desde el aeropuerto de Zihuatanejo
                    encuentras autobuses a Barra de Nexpa, o podemos ocuparnos y
                    coordinar un servicio de pick up hasta las cabañas.
                  </p>
                </div>
                <a
                  className="scale-75 mt-8"
                  href="https://www.google.com/maps/place/VillasCheyos/@18.0873306,-102.7937807,17z/data=!4m5!3m4!1s0x84311e0f2ceb3011:0x75f2da2ea9a9540!8m2!3d18.087363!4d-102.7915482"
                >
                  <StaticImage
                    width={560}
                    src="../images/location.png"
                    alt="location Nexpa"
                    placeholder="tracedSVG"
                    className="rounded-lg shadow-lg w-full mt-8"
                  />
                </a>
              </div>
            </div>
          </div>
        </>
      ))}
    </Layout>
  );
};

export default CardPage;
